/*
  * general style
*/

@media print {
  body * {
    visibility: hidden;
  }
  .print-view,
  .print-view * {
    visibility: visible;
  }
  .print-view {
    position: absolute;
    left: 0;
    top: 0;
  }
}
@media screen {
  .print-view {
    visibility: hidden;
    display: none;
  }
}

.pointer {
  cursor: pointer;
}

/* page message Should go into page message too*/
.page-message {
  padding: 5px;
  background-color: #f7c46c;
  width: 100%;
}

@media (max-width: 640px) {
  .border\@s {
    border: none !important;
  }
  .shadow\@s {
    box-shadow: none !important;
  }
}

@media (max-width: 959px) {
  .border\@m {
    border: none !important;
  }
  .shadow\@m {
    box-shadow: none !important;
  }
}

.no-border {
  border: none;
}
.z-index-500 {
  z-index: 500 !important;
}

.z-index-1000 {
  z-index: 1000 !important;
}

.z-index-1500 {
  z-index: 1500 !important;
}

._10px {
  font-size: 10px;
}
i._10px {
  position: relative;
  top: -2px;
}

.b-1-solid {
  border: 1px #ddd solid;
}
.bb-1-solid {
  border-bottom: 1px #ddd solid;
}
.bt-1-solid {
  border-top: 1px #ddd solid;
}
.bl-1-solid {
  border-left: 1px #ddd solid;
}
.br-1-solid {
  border-right: 1px #ddd solid;
}
.radius-4 {
  border-radius: 4px;
}

.notie-container {
  z-index: 1500 !important;
}

.hide-arrow.dropdown-toggle::after {
  display: none;
}
